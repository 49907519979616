import _is from "type/value/is";
import _ensure from "type/value/ensure";
import _ensure2 from "type/plain-function/ensure";
import _copy from "es5-ext/object/copy";
import _normalizeOptions from "es5-ext/object/normalize-options";
import _map from "es5-ext/object/map";
var exports = {};
var isValue = _is,
    ensureValue = _ensure,
    ensurePlainFunction = _ensure2,
    copy = _copy,
    normalizeOptions = _normalizeOptions,
    map = _map;
var bind = Function.prototype.bind,
    defineProperty = Object.defineProperty,
    hasOwnProperty = Object.prototype.hasOwnProperty,
    define;

define = function (name, desc, options) {
  var value = ensureValue(desc) && ensurePlainFunction(desc.value),
      dgs;
  dgs = copy(desc);
  delete dgs.writable;
  delete dgs.value;

  dgs.get = function () {
    if (!options.overwriteDefinition && hasOwnProperty.call(this, name)) return value;
    desc.value = bind.call(value, options.resolveContext ? options.resolveContext(this) : this);
    defineProperty(this, name, desc);
    return this[name];
  };

  return dgs;
};

exports = function (props
/*, options*/
) {
  var options = normalizeOptions(arguments[1]);
  if (isValue(options.resolveContext)) ensurePlainFunction(options.resolveContext);
  return map(props, function (desc, name) {
    return define(name, desc, options);
  });
};

export default exports;